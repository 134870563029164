.textFieldWithBorder > div{
  border-radius: 5px;
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
}

.textField > div{
  border-radius: 5px;
  background: #FFFFFF;
}

.textFieldGrey > div{
  background-color: #F0F0F0;
}

.textField > div > input{
  padding: 12px 16px
}

.textFieldWithBorder > div > input{
  padding: 12px 16px
}

 .textFieldGrey > div > input{
  padding: 12px 16px
}


.label {
  font-family: 'Rubik' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #000 !important;
  padding-left: 5px !important;
  display: inline-flex;
}

.selectSearch {
  border-radius: 5px;
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
}

.selectInput {
  border-radius: 5px;
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
}

.selectInput > div {
  padding: 12px 16px;
  font-family: 'Inter';
  font-size: 15px;
  font-weight: 600;
}

.codeField {
  width: 50px;
  height: 60px;
  text-align-last: center;

  @media (max-width: 640px) {
    width: auto;
    height: auto;
  }
}

.searchContainer{
  width: -webkit-fill-available
}

.searchContainer > div {
  width: inherit;
}

