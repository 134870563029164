.container {
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.container:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}

