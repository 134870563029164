.modal > .MuiModal-backdrop {
  background-color: red;
}

.pinContainer{
  display: flex;
  justify-content: center;
}

.companyContainer {
  max-width: 84.5% !important;
  @media (max-width: 1031px) {
    max-width: 86% !important
  }
  @media (max-width: 599px) {
    max-width: 100% !important
  }
}

